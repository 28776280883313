<template>
  <div class="vipGrade">
    <div class="Card">
      <div class="left">
        <span>{{$t('mine.vipjifen.myjifen')}}:</span>
        <div>{{userInfo.integral}}</div>
      </div>
      <div class="right">
        <span>{{$t('mine.vipjifen.dangqiandengji')}}:</span>
        <img src="../../../public/imgs/mine/card.png" alt="">
        <span class="span">{{ getname}}</span>
      </div>
    </div>
    <div class="tilte">
      <div></div>
      <span>{{$t('mine.vipjifen.title')}}</span>
    </div>
    <el-table
      :data="tableData"
      :row-style="{height:'80px'}"
      :header-cell-style="{background:'#F8F8F8'}"
      style="width: 100%;border:1px solid #ECECEC;">
      <el-table-column
        class-name="tageColumn"
        prop="createTime"
        :label="$t('mine.vipjifen.tiem')"
        width="180"
        align="center">
      </el-table-column>
      <el-table-column
        prop="name"
        :label="$t('mine.vipjifen.name')"
        width="180"
        align="center">
      </el-table-column>
      <el-table-column
        prop="integral"
        :label="$t('mine.vipjifen.jifen')"
        align="center">
        <template slot-scope="scope">
          <div :style="{color:(scope.row.type==1||scope.row.type==4)?'red':'green'}">
            {{(scope.row.type==1||scope.row.type==4)? '+':'-'}}{{scope.row.integral}}
          </div>
        </template>
      </el-table-column>
      <el-table-column
        prop="vipName"
        :label="$t('mine.vipjifen.dengji')"
        align="center">
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import { listUserIntegralRecord,getUserDetail } from '@/api/main.js'
//import {mapState} from 'vuex'
export default {
  data() {
    return {
      title:'退款提示',
      indexStatus:0,//0待支付，1待发货，2待收货，3待评价，4已完成，5已取消，6退款/售后申请，7审核成功，8审核失败，9已退款。默认0
      tableData: [],
      userInfo:{}
    }
  },
  computed:{
    getname(){
        if(this.isChinese()){
          return this.userInfo.vipName
        }else{
          return this.userInfo.vipNameEng
        }
    },
  },
  filter:{
   
  },
  mounted() {
    
    this.listUserIntegralRecord();
    this.getUserDetail();
  },
  methods: {
    getUserDetail(){
      getUserDetail({}).then(res=>{
        if(res.code == 0){
          if(this.isChinese()){
            this.userInfo = res.data;
          }else{
            this.userInfo = {
              ...res.data,
              vipName:res.data.vipNameEng
            }
          }
        }else{
          this.showMessage(res)
        }
      })
    },
    listUserIntegralRecord(){
      listUserIntegralRecord({
        pageNum:1,
        pageSize:100
      }).then(res=>{
        if(res.code == 0){
          if(this.isChinese()){
            this.tableData = res.data.rows.filter(data=>{
              data.names = data.name
              return data
            })
          }else{
            this.tableData = res.data.rows.filter(data=>{
              data.names = data.name//后端支付订单为加开通会员升级为减积分
              data.name = data.nameEng
              data.vipName = data.vipNameEng
              return data
            })
            console.log(this.tableData)
          }
        }else{
          this.showMessage(res)
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.vipGrade {
  width: 880px; 
  .Card{
    width: 880px;
    background: #F5F5F5;
    margin-bottom: 20px;
    padding: 28px 40px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    .left,.right{
      div{
        font-size: 36px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 600;
        color: #333333;
        margin-top : 30px;
        text-align: right;
        width: 200px;
      }
      span{
        font-size: 16px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #333333;
      }
      img{
        height: 162px;
        width: 294px;
        vertical-align: top;
        margin-left: 30px;
      }

    }
    .right{
      position: relative;
      .span{
        position: absolute;
        left:159px;
        bottom: 36px;
        font-size: 16px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #333333;
      }
    }
  }
  .tilte {

    display: flex;
    align-items: center;
    padding: 20px 0px;

    div {
      width: 2px;
      height: 24px;
      background: #333333;
      margin-right: 10px;
    }

    span {
      font-size: 20px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #333333;
    }
  }

 

}
.tageColumn{
  color:#f0f;
  border:1px solid #f0f;
}
</style>